import React, {SetStateAction} from "react";

export default interface RealEstateContext {
    realEstates: RealEstateInterface[];

    setHouses(realEstate: RealEstateInterface[]): void,

    filterProps: FilterProps | null,
    isLoading: boolean,

    setIsLoading(isLoading: SetStateAction<boolean>): void,

    resetFilter: () => void,
    offset: number,

    setOffset(offset: number): void,

    filter: Filter | null,

    setFilter(filter: SetStateAction<Filter | null>): void
}

export const bannerData = [
    {1200: 'Premium Product'},
    {4500: 'Happy Customer'},
    {'240': 'Award Winning'}
]

export const cities = [
    "Phuket",
    "Pattaya",
]

export interface Address {
    id: number;
    real_estate_id: number,
    country: string;
    region: string;
    district: string;
    regionInCity: string;
    street: string;
    index: string;
    houseNumber: string;
    latitude: number | null;
    longitude: number | null;
}

export interface AddressDto {
    id?: number;
    real_estate_id?: number,
    country: string;
    region: string;
    district: string;
    regionInCity: string;
    street: string;
    index: string;
    houseNumber: string;
    latitude: number | null;
    longitude: number | null;
}

export interface Photo {
    id: number;
    realEstateId: number;
    imageUrl: string;
}

export interface PhotoDto {
    id?: number;
    realEstateId?: number;
    imageUrl: string;
}

export interface RealEstateInterface {
    id: number;
    ownerId: number;
    name: string;
    price: number;
    status: string;
    newBuilding: boolean;
    type: string;
    roomCount: number;
    area: number;
    description: string;
    constructionYear: number;
    floor: number;
    numberOfFloors: number;
    addressDto: AddressDto;
    createdAt: Date;
    updatedAt: Date;
    photos: Photo[];
    saleFeaturesDto: SaleFeatures | null,
    rentalFeaturesDto: RentalFeatures | null
}

export interface RealEstateDto {
    id?: number;
    ownerId: number;
    name: string;
    price: number;
    status: string;
    newBuilding: boolean;
    type: string;
    roomCount: number;
    area: number;
    description: string;
    constructionYear: number;
    floor: number;
    numberOfFloors: number;
    addressDto: AddressDto;
    createdAt?: Date;
    updatedAt?: Date;
    photos?: PhotoDto[];
    saleFeaturesDto: SaleFeatures | null,
    rentalFeaturesDto: RentalFeatures | null
}

export function mapRealEstateInterfaceToDto(realEstate: RealEstateInterface): RealEstateDto {
    return {
        id: realEstate.id,
        ownerId: realEstate.ownerId,
        name: realEstate.name,
        price: realEstate.price,
        status: realEstate.status,
        newBuilding: realEstate.newBuilding,
        type: realEstate.type,
        roomCount: realEstate.roomCount,
        area: realEstate.area,
        description: realEstate.description,
        constructionYear: realEstate.constructionYear,
        floor: realEstate.floor,
        numberOfFloors: realEstate.numberOfFloors,
        addressDto: realEstate.addressDto,
        createdAt: realEstate.createdAt,
        updatedAt: realEstate.updatedAt,
        photos: realEstate.photos ? realEstate.photos.map(mapPhotoToDto) : [],
        saleFeaturesDto: realEstate.saleFeaturesDto,
        rentalFeaturesDto: realEstate.rentalFeaturesDto
    };
}

function mapPhotoToDto(photo: Photo): PhotoDto {
    return {
        id: photo.id,
        imageUrl: photo.imageUrl,
        realEstateId: photo.realEstateId,
    };
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: Role;
    password: string;
    photoUrl: string | null;
}

export interface UserWithoutPassword {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: Role;
    photoUrl: string | null;
}

export interface UserDto {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: Role;
    password: string;
    photoUrl: string | null;
}

export enum Role {
    ADMIN = "ADMIN",
    USER = "USER"
}

export enum Status {
    RENT = "RENT",
    SALE = "SALE",
    /*INVEST = "Инвестиция в недвижимость"*/
}

export enum StatusForUsers {
    RENT = "RENT",
    SALE = "SALE"
}

export const statusForUsersMapping = {
    [StatusForUsers.RENT]: "RENT",
    [StatusForUsers.SALE]: "SALE",
    /*[StatusForUsers.INVEST]: "INVEST"*/
};

export const statusMapping = {
    [Status.RENT]: "RENT",
    [Status.SALE]: "SALE",
    /*[Status.INVEST]: "INVEST"*/
};

export enum BuildingType {
    HOUSE = "HOUSE",
    CONDO = "CONDO",
    VILLA = "VILLA",
    APARTMENT = "APARTMENT",
    ROOM = "ROOM",
    LAND_PLOT = "LAND_PLOT",
    COMMERCIAL = "COMMERCIAL",
    HOTEL = "HOTEL"
}

/*export const buildingTypeMapping = {
    [BuildingType.HOUSE]: "HOUSE",
    [BuildingType.APARTMENT]: "APARTMENT",
    [BuildingType.ROOM]: "ROOM",
    [BuildingType.LAND_PLOT]: "LAND_PLOT",
    [BuildingType.COMMERCIAL]: "COMMERCIAL",
    [BuildingType.CONDO]: "CONDO",
    [BuildingType.VILLA]: "VILLA",
};*/

export enum Interior {
    ANY = "ANY",
    THAI = "THAI",
    EUROPE = "EUROPE",
    DESIGNER = "DESIGNER",
    WITHOUT_FURNITURE = "WITHOUT_FURNITURE"
}

/*export const interiorMapping = {
    [Interior.ANY]: "ANY",
    [Interior.THAI]: "THAI",
    [Interior.EUROPE]: "EUROPE",
    [Interior.DESIGNER]: "DESIGNER",
    [Interior.WITHOUT_FURNITURE]: "WITHOUT_FURNITURE",
};*/

export interface FilterProps {
    buildingType: string | null,
    setBuildingType(type: SetStateAction<BuildingType>): void,
    status: StatusForUsers | null,
    setStatus(status: SetStateAction<StatusForUsers>): void,
    tambon: string | null,
    setTambon(city: SetStateAction<string | null>): void,
    areaFrom: number | null,
    setAreaFrom(area: SetStateAction<number | null>): void,
    areaTo: number | null,
    setAreaTo(area: SetStateAction<number | null>): void,
    priceFrom: number | null,
    setPriceFrom(price: SetStateAction<number | null>): void,
    priceTo: number | null,
    setPriceTo(price: SetStateAction<number | null>): void,
    newBuilding: boolean | null,
    setNewBuilding(newBuilding: SetStateAction<boolean | null>): void,
    roomItems: boolean[],
    setRoomItems(roomItems: SetStateAction<boolean[]>): void,
    setRentTime(rentTime: SetStateAction<string | null>): void,
    saleFeatures: SaleFeatures,
    setSaleFeatures(saleFeatures: SetStateAction<SaleFeatures>): void,
    rentalFeatures: RentalFeatures,
    setRentalFeatures(rentalFeatures: SetStateAction<RentalFeatures>): void,
    handleSaleFeaturesChange(event: React.ChangeEvent<HTMLInputElement>): void,
    handleRentalFeaturesChange(event: React.ChangeEvent<HTMLInputElement>): void,
    setSleepingPlaces(value: number): void,
    setRentalTime(value: string | null): void,
    setInteriorFeatures(value: string): void,
    resetFeaturesFilter(): void,
    isOpen: boolean,
    onOpen: () => void,
    onClose: () => void,
    searchHandler: () => void,
    resetFilters: () => void
}

export interface Filter {
    buildingType: string | null,
    status: string | null,
    tambon: string | null,
    areaFrom: number | null,
    areaTo: number | null,
    priceFrom: number | null,
    priceTo: number | null,
    newBuilding: boolean | null,
    roomItems: boolean[],
    saleFeaturesDto: SaleFeatures | null,
    rentalFeaturesDto: RentalFeatures | null
}

export interface AdditionalFilter {
    interiorItems: boolean[] | null,
    facilityItems: boolean[] | null,
    rentTime: string | null,
    ruleItems: boolean[] | null
}

export interface AdditionalParameters {
    id: number,
    realEstateId: number,
    interior: string | null,
    facility: string | null,
    rentTime: string | null,
    rules: string | null
}

export interface AdditionalParametersDto {
    interior: string | null,
    facility: string | null,
    rentTime: string | null,
    rules: string | null
}

export const facilities = ["SWIMMING_POOL", "PARKING", "GYM", "WIFI", "WASHING_MACHINE", "CLEANING", "SMOKING_AREA"]
const facilitiesRu = ["Бассейн", "Парковка", "Спортзал", "WI-FI", "Стиральная машина", "Уборка", "Зона для курения"]
export const rules = ["CHILD", "PET"]

type FacilitiesMap = {
    [key: string]: string;
};

const facilitiesMap: FacilitiesMap = {
    "SWIMMING_POOL": "Бассейн",
    "PARKING": "Парковка",
    "GYM": "Спортзал",
    "WIFI": "WI-FI",
    "WASHING_MACHINE": "Стиральная машина",
    "CLEANING": "Уборка",
    "SMOKING_AREA": "Зона для курения"
};

export function translateFacilities(facilities: string[]): string {
    const facilitiesRu: string[] = [];

    for (const facility of facilities) {
        facilitiesRu.push(facilitiesMap[facility]);
    }

    return facilitiesRu.join(", ");
}

export interface Favorite {
    userId: number,
    realEstateId: number
}

export interface RentalFeatures {
    id: number;
    realEstateId: number;
    rentalTime: string | null;
    withPet: boolean;
    withChildren: boolean;
    airportTransfer: boolean;
    beachTransfer: boolean;
    concierge: boolean;
    openParking: boolean;
    closedParking: boolean;
    seaView: boolean;
    lakeView: boolean;
    mountainView: boolean;
    forestView: boolean;
    courtyard: boolean;
    pool: boolean;
    outdoorShower: boolean;
    bbqArea: boolean;
    outdoorDining: boolean;
    poolTable: boolean;
    gymEquipment: boolean;
    car: boolean;
    motorbike: boolean;
    sleepingPlaces: number;
    workspace: boolean;
    wifi: boolean;
    tv: boolean;
    airConditioning: boolean;
    fridge: boolean;
    dishwasher: boolean;
    diningTable: boolean;
    kitchenUtensils: boolean;
    bathtub: boolean;
    shower: boolean;
    washingMachine: boolean;
    cleaning: boolean;
    linenChange: boolean;
    videoSurveillance: boolean;
    closedTerritory: boolean;
    smokeDetector: boolean;
    firstAidKit: boolean;
    fireExtinguisher: boolean;
    carbonMonoxideDetector: boolean;
    smokingArea: boolean;
    interior: Interior | null
}

export interface SaleFeatures {
    id: number;
    realEstateId: number;
    concierge: boolean;
    gatedCommunity: boolean;
    openParking: boolean;
    closedParking: boolean;
    closedTerritory: boolean;
    gym: boolean;
    playground: boolean;
    recreationArea: boolean;
    pool: boolean;
    terrace: boolean;
    fridge: boolean;
    dishwasher: boolean;
    diningTable: boolean;
    kitchenUtensils: boolean;
    appliances: boolean;
    tv: boolean;
    airConditioning: boolean;
    wardrobe: boolean;
    washingMachine: boolean;
    smokingArea: boolean;
    wifi: boolean ;
    interior: Interior | null
}

