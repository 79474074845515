import {useTranslation} from "react-i18next";
import React, {useContext, useEffect, useState} from "react";
import {SearchContext} from "../../context/SearchProvider";
import {Box, Button, ButtonGroup, Checkbox, Divider, Select, Stack, Text, VStack} from "@chakra-ui/react";
import {Interior} from "../../api/model";

export default function SaleFeaturesFilter() {
    const {t} = useTranslation();
    const {setInteriorFeatures, rentalFeatures, handleRentalFeaturesChange, setRentalTime} = useContext(SearchContext);
    const [pressedButton, setPressedButton] = useState<string | null>(null)

    useEffect(() => {
        setPressedButton(rentalFeatures.rentalTime)
    }, []);

    useEffect(() => {
        if (pressedButton) {
            setRentalTime(pressedButton)
        }
    }, [pressedButton])

    useEffect(() => {
        setPressedButton(rentalFeatures.rentalTime)
    }, [rentalFeatures.rentalTime]);

    return (
        <>
            <VStack
                justify='space-between'
                my='24px' spacing={"16px"}
            >
                <Box width={"100%"}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('interior')}</Text>
                    <Select
                        height="44px"
                        width="100%"
                        variant="filled"
                        colorScheme="green"
                        placeholder={t('interior')}
                        onChange={e => setInteriorFeatures(e.target.value)}
                    >
                        {
                            Object.values(Interior).map((type) => (
                                <option key={type} value={type}>
                                    {t(`Interior.${type}`)}
                                </option>
                            ))
                        }
                    </Select>
                </Box>


                <Box width={"100%"}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('rent_time')}</Text>
                    <ButtonGroup
                        variant="contained"
                        aria-label={t('rent_time')}
                        spacing={0}
                        width="100%"
                        alignItems={"center"}
                        borderColor="#EDF2F7"
                        borderRadius={"6px"}
                        borderWidth="2px"
                    >
                        <Button
                            height="44px"
                            width="35%"
                            borderRadius={"6px 0 0 6px"}
                            textAlign="center"
                            textColor="black"
                            fontSize="1rem"
                            fontWeight=""
                            overflow={"hidden"}
                            backgroundColor={pressedButton === "daily" ? "#2d9d92" : "#ffffff"}
                            onClick={() => setPressedButton("daily")}
                        >
                            {t('daily')}
                        </Button>
                        <Divider orientation='vertical' height="70%"/>
                        <Button
                            height="44px"
                            width="65%"
                            borderRadius={"0 6px 6px 0"}
                            textAlign="center"
                            textColor="black"
                            fontSize="1rem"
                            fontWeight=""
                            overflow={"hidden"}
                            backgroundColor={pressedButton === "long" ? "#2d9d92" : "#ffffff"}
                            onClick={() => setPressedButton("long")}
                        >
                            {t('long_time')}
                        </Button>
                    </ButtonGroup>
                </Box>

                <Box width={"100%"}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('facilities')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.airportTransfer || false}
                        name={"airportTransfer"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('airportTransfer')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.beachTransfer || false}
                        name={"beachTransfer"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('beachTransfer')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.concierge || false}
                        name={"concierge"}
                        onChange={handleRentalFeaturesChange || false}
                    >
                        {t('concierge')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.openParking || false}
                        name={"openParking"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('openParking')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.closedParking || false}
                        name={"closedParking"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('closedParking')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.smokingArea || false}
                        name={"smokingArea"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('smokingArea')}
                    </Checkbox>
                </Box>

                <Box width={"100%"}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('facilitiesInside')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.workspace || false}
                        name={"workspace"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('workspace')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.wifi || false}
                        name={"wifi"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('wifi')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.tv || false}
                        name={"tv"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('tv')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.airConditioning || false}
                        name={"airConditioning"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('airConditioning')}
                    </Checkbox>
                </Box>

                <Box width={"100%"}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('peculiarities')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.seaView || false}
                        name={"seaView"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('seaView')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.lakeView || false}
                        name={"lakeView"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('lakeView')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.mountainView}
                        name={"mountainView"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('mountainView')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.forestView}
                        name={"forestView"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('forestView')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.courtyard}
                        name={"courtyard"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('courtyard')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.pool}
                        name={"pool"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('pool')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.outdoorShower}
                        name={"outdoorShower"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('outdoorShower')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.bbqArea}
                        name={"bbqArea"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('bbqArea')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.outdoorDining}
                        name={"outdoorDining"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('outdoorDining')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.poolTable}
                        name={"poolTable"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('poolTable')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.gymEquipment}
                        name={"gymEquipment"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('gymEquipment')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.car}
                        name={"car"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('car')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.motorbike}
                        name={"motorbike"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('motorbike')}
                    </Checkbox>
                </Box>

                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('kitchen')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.fridge}
                        name={"fridge"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('fridge')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.dishwasher}
                        name={"dishwasher"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('dishwasher')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.diningTable}
                        name={"diningTable"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('diningTable')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.kitchenUtensils}
                        name={"kitchenUtensils"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('kitchenUtensils')}
                    </Checkbox>
                </Box>

                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('bathroom')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.bathtub}
                        name={"bathtub"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('bathtub')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.shower}
                        name={"shower"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('shower')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.washingMachine}
                        name={"washingMachine"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('washingMachine')}
                    </Checkbox>
                </Box>

                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('additionally')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.cleaning}
                        name={"cleaning"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('cleaning')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.linenChange}
                        name={"linenChange"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('linenChange')}
                    </Checkbox>
                </Box>

                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('rules')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.withPet}
                        name={"withPet"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('with_pets')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.withChildren}
                        name={"withChildren"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('with_childes')}
                    </Checkbox>
                </Box>
                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('safety')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.videoSurveillance}
                        name={"videoSurveillance"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('videoSurveillance')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.closedTerritory}
                        name={"closedTerritory"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('closedTerritory')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.smokeDetector}
                        name={"smokeDetector"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('smokeDetector')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.firstAidKit}
                        name={"firstAidKit"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('firstAidKit')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.fireExtinguisher}
                        name={"fireExtinguisher"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('fireExtinguisher')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={rentalFeatures.carbonMonoxideDetector}
                        name={"carbonMonoxideDetector"}
                        onChange={handleRentalFeaturesChange}
                    >
                        {t('carbonMonoxideDetector')}
                    </Checkbox>
                </Box>
            </VStack>
        </>
    )
}