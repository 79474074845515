import React, {useContext, useEffect, useState} from "react";
import {Select} from "@chakra-ui/react";
import {StatusForUsers} from "../../api/model";
import {SearchContext} from "../../context/SearchProvider";
import {useTranslation} from "react-i18next";

export default function StatusFilter() {
    const {t} = useTranslation();
    const searchContext = useContext(SearchContext)
    const [localStatus, setLocalStatus] = useState<StatusForUsers | null>(StatusForUsers.SALE)
    const statusSelectHandler = (status: "RENT" | "SALE") => {
        if (status === "RENT") {
            setLocalStatus(StatusForUsers.RENT)
            searchContext.setStatus(StatusForUsers.RENT)
        } else if (status === "SALE") {
            setLocalStatus(StatusForUsers.SALE)
            searchContext.setStatus(StatusForUsers.SALE)
        }
    }

    useEffect(() => {
            setLocalStatus(searchContext.status)
    }, [searchContext.status]);

    return (
        <Select
            height="44px"
            width="100%"
            variant='filled'
            onChange={s => statusSelectHandler(s.target.value as keyof typeof StatusForUsers)}
            value={localStatus || StatusForUsers.SALE}
        >
            {
                Object.values(StatusForUsers).map((type) => (
                    <option key={type} value={type}>
                        {t(`StatusForUsers.${type}`)}
                    </option>
                ))
            }
        </Select>
    )
}