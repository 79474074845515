import {
    Box,
    Button,
    chakra,
    Flex,
    FormControl, HStack, Icon, IconButton, Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu, MenuButton, MenuItem, MenuList,
    Stack,
    ToastId, useBreakpointValue,
    useToast
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Role, UserWithoutPassword} from "../../api/model";
import {updateUserData, uploadPhotoProfile, userByEmail, userPhoto} from "../../api/Data";
import {FaUserAlt} from "react-icons/fa";
import {jwtDecode} from "jwt-decode";
import photo from "../../assets/images/agents/profile.png";
import {ImageInfo} from "../Images/Uploader";
import {useTranslation} from "react-i18next";
import Crop from "./Crop";

import russia from "../../assets/images/icons/russia.png"
import english from "../../assets/images/icons/english.png"
import thai from "../../assets/images/icons/thai.png"
import china from "../../assets/images/icons/china.png"

import { GrLanguage } from "react-icons/gr";

const CFaUserAlt = chakra(FaUserAlt);

export const UserProfile = () => {
    const {t, i18n} = useTranslation();
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phone, setPhone] = useState("")
    const toast = useToast();
    const toastIdRef = React.useRef<ToastId>()
    const navigate = useNavigate();
    const [image, setImage] = useState<ImageInfo | null>(null);
    const [newImage, setNewImage] = useState<Blob | null>(null);
    const [tempImage, setTempImage] = useState<ImageInfo | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const isMobile = useBreakpointValue({base: true, lg: false})
    const [isCropOpen, setIsCropOpen] = useState<boolean>(false)
    const [lang, setLang] = useState<string>(i18n.language)

    const exitButtonHandle = () => {
        localStorage.clear();
        navigate("/")
    }

    function langName(lang: string) {
        switch (lang) {
            case "ru": {
                return "Русский"
            }
            case "en": {
                return "English"
            }
            case "th": {
                return "แบบไทย"
            }
            case "zh-CN": {
                return "中国人"
            }
            default: {
                return "English"
            }
        }
    }

    useEffect(() => {
        i18n.changeLanguage(lang)
    }, [lang]);

    function updatePhoto() {
        const userId = localStorage.getItem("userId")
        toastIdRef.current = toast({
            title: t('photo_update'),
            status: 'loading',
            isClosable: true,
            position: 'top'
        })

        if (userId !== null && newImage !== null) {
            const file = new File([newImage], 'image.jpeg', {
                type: newImage.type,
            })

            const imageInfo = {
                id: 0,
                file: file,
                url: "",
                isOld: false
            }

            uploadPhotoProfile(parseInt(userId), imageInfo).then(res => {
                if (res && toastIdRef.current !== undefined) {
                    toast.update(toastIdRef.current, {
                        title: t('photo_updated_success'),
                        status: 'success',
                        duration: 1000,
                        isClosable: true,
                        position: 'top'
                    })
                    window.location.reload()
                } else if (toastIdRef.current !== undefined) {
                    toast.update(toastIdRef.current, {
                        title: t('photo_updated_error'),
                        status: 'error',
                        duration: 1000,
                        isClosable: true,
                        position: 'top'
                    })
                }
            }).catch(e => {
                if (toastIdRef.current !== undefined) {
                    toast.update(toastIdRef.current, {
                        title: t('photo_updated_error'),
                        status: 'error',
                        duration: 1000,
                        isClosable: true,
                        position: 'top'
                    })
                }
                console.log(e)
            })
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('accessToken')
        const userId = localStorage.getItem("userId")

        if (token != null) {
            const decodedJwt = jwtDecode(token);
            if (decodedJwt.sub != null && userId !== null) {
                userByEmail(decodedJwt.sub, token)
                    .then(user => {
                        setFirstName(user.firstName)
                        setLastName(user.lastName)
                        setPhone(user.phone)
                        setImageUrl(userPhoto(parseInt(userId)))
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }
        }
    }, [])

    const updateProfileHandleClick = async () => {
        if (firstName === "") {
            toast({
                title: t('enter_name'),
                status: 'error',
                duration: 500,
                isClosable: true,
                position: 'top'
            })
            return
        }

        if (lastName === "") {
            toast({
                title: t('enter_lastname'),
                status: 'error',
                duration: 500,
                isClosable: true,
                position: 'top'
            })
            return
        }

        if (phone === "") {
            toast({
                title: t('enter_phone'),
                status: 'error',
                duration: 500,
                isClosable: true,
                position: 'top'
            })
            return
        }

        const roleString = localStorage.getItem("role");
        let role = Role.USER
        let photoUrl = null
        let photoUrlString = localStorage.getItem('photoUrl')
        let token = localStorage.getItem('accessToken')

        if (roleString === "ADMIN") {
            role = Role.ADMIN
        }

        if (photoUrlString != null) {
            photoUrl = photoUrlString
        }

        let user: UserWithoutPassword = {
            id: parseInt(localStorage.getItem("userId")!!),
            firstName: firstName,
            lastName: lastName,
            email: localStorage.getItem('email')!!,
            phone: phone,
            role: role,
            photoUrl: photoUrl
        }

        toastIdRef.current = toast({
            title: t('data_updating'),
            status: 'loading',
            isClosable: true,
            position: 'top'
        })

        if (token != null) {
            await updateUserData(user, token).then(result => {
                if (result) {
                    localStorage.setItem('firstName', firstName);
                    localStorage.setItem('lastName', lastName);
                    localStorage.setItem('phone', phone);
                    localStorage.setItem('role', role);

                    if (toastIdRef.current) {
                        toast.update(toastIdRef.current, {
                            title: t('data_updated_success'),
                            status: 'success',
                            duration: 1000,
                            isClosable: true,
                            position: 'top'
                        })
                    }
                } else {
                    throw new Error('Error!');
                }
            }).catch(e => {
                console.log(e)
                if (toastIdRef.current) {
                    toast.update(toastIdRef.current, {
                        title: t('data_updated_error'),
                        status: 'error',
                        duration: 1000,
                        isClosable: true,
                        position: 'top'
                    })
                }
            })
        } else {
            toast.update(toastIdRef.current, {
                title: t('need_auth'),
                status: 'error',
                duration: 1000,
                isClosable: true,
                position: 'top'
            })
        }
    }

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            const fileList = Array.from(files);

            const newImages = fileList.map((file) => ({
                id: 0,
                file: file,
                url: URL.createObjectURL(file),
                isOld: false
            }));

            setTempImage(newImages[0]);
        }
    };

    useEffect(() => {
        if (tempImage !== null) {
            setIsCropOpen(true)
        }
    }, [tempImage]);

    useEffect(() => {
        if (newImage) {
            const file = new File([newImage], 'image.jpeg', {
                type: newImage.type,
            })

            const imageInfo = {
                id: 0,
                file: file,
                url: URL.createObjectURL(newImage),
                isOld: false
            }
            setImage(imageInfo)
            console.log(imageInfo)
        }
    }, [newImage]);

    const cropClose = () => {
        setIsCropOpen(false)
        setTempImage(null)
    }

    const handleFormClick = () => {
        const inputField = document.querySelector(".input-field") as HTMLInputElement | null;
        if (inputField) {
            inputField.click();
        }
    };

    const toMy = () => {
        navigate("/my")
    }

    return (
        <Flex
            flexDirection="column"
            width="100%"
            height="80vh"
            justifyContent="center"
            alignItems="center"
        >
            {
                isMobile && <HStack width="100%" justifyContent={"end"}>
                    <Menu>
                        <MenuButton
                            as={IconButton}
                            icon={<Icon as={GrLanguage}/>}
                            aria-label='Language'
                            background={"#2d9d92"}
                            margin="5px"
                        >
                            {langName(lang)}
                        </MenuButton>
                        <MenuList>
                            <MenuItem minH='48px' onClick={() => setLang("ru")}>
                                <Image
                                    boxSize='2rem'
                                    borderRadius='full'
                                    src={russia}
                                    alt='Russian'
                                    mr='12px'
                                />
                                <span>Русский</span>
                            </MenuItem>
                            <MenuItem minH='40px' onClick={() => setLang("en")}>
                                <Image
                                    boxSize='2rem'
                                    borderRadius='full'
                                    src={english}
                                    alt='English'
                                    mr='12px'
                                />
                                <span>English</span>
                            </MenuItem>
                            <MenuItem minH='40px' onClick={() => setLang("th")}>
                                <Image
                                    boxSize='2rem'
                                    borderRadius='full'
                                    src={thai}
                                    alt='Thai'
                                    mr='12px'
                                />
                                <span>แบบไทย</span>
                            </MenuItem>
                            <MenuItem minH='40px' onClick={() => setLang("zh-CN")}>
                                <Image
                                    boxSize='2rem'
                                    borderRadius='full'
                                    src={china}
                                    alt='Chinese'
                                    mr='12px'
                                />
                                <span>中国人</span>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </HStack>
            }

            <Stack
                flexDir="column"
                mb="2"
                alignItems="center"
                justifyContent={"center"}
                height={"100%"}
            >
                <Box minW={{base: "90%", lg: "468px"}}>
                    <Stack
                        spacing={4}
                        backgroundColor="whiteAlpha.900"
                        alignItems="center"
                    >
                        <div onClick={handleFormClick}>
                            <input
                                type="file"
                                accept="image/*"
                                className="input-field"
                                multiple
                                hidden
                                onChange={handleFileChange}
                            />
                            <Image
                                borderRadius='full'
                                boxSize={{base: "120px", lg: "200px"}}
                                src={image?.url || imageUrl || photo}
                            />
                        </div>

                        {
                            newImage && <Button
                                onClick={() => updatePhoto()}
                                backgroundColor={"#2d9d92"}
                                width="100%"
                                _hover={{background: "#9cb1b1"}}
                            >
                                {t('update_photo')}
                            </Button>
                        }

                        <FormControl>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                    <CFaUserAlt color="#2d9d92"/>
                                </InputLeftElement>
                                <Input
                                    type="lastname"
                                    placeholder={t('lastname')}
                                    value={lastName}
                                    onChange={e => {
                                        setLastName(e.target.value)
                                    }}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                    <CFaUserAlt color="#2d9d92"/>
                                </InputLeftElement>
                                <Input
                                    type="firstname"
                                    placeholder={t('first_name')}
                                    value={firstName}
                                    onChange={e => {
                                        setFirstName(e.target.value)
                                    }}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl>
                            <InputGroup>
                                <InputLeftElement pointerEvents="none">
                                    <CFaUserAlt color="#2d9d92"/>
                                </InputLeftElement>
                                <Input
                                    type="phone"
                                    placeholder={t('phone_number')}
                                    value={phone}
                                    onChange={e => {
                                        setPhone(e.target.value)
                                    }}
                                />
                            </InputGroup>
                        </FormControl>
                        <Button
                            onClick={updateProfileHandleClick}
                            backgroundColor={"#2d9d92"}
                            width="100%"
                            _hover={{background: "#9cb1b1"}}
                        >
                            {t('update_user_data')}
                        </Button>
                        <Button
                            onClick={exitButtonHandle}
                            backgroundColor={"#2d9d92"}
                            width="100%"
                            _hover={{background: "#9cb1b1"}}
                        >
                            {t('log_out')}
                        </Button>

                        {
                            isMobile &&
                            <Button
                                marginTop={"25px"}
                                width="100%"
                                variant='outline'
                                color={"#2d9d92"}
                                onClick={toMy}
                            >
                                {t('my_adv')}
                            </Button>
                        }
                    </Stack>
                </Box>
            </Stack>

            {
                isCropOpen && tempImage &&
                <Crop image={tempImage?.url} close={() => cropClose()} setNewImage={setNewImage}/>
            }

        </Flex>
    );
}