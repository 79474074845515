import React, {useContext} from "react";
import {Select} from "@chakra-ui/react";
import {BuildingType} from "../../api/model";
import {SearchContext} from "../../context/SearchProvider";
import {useTranslation} from "react-i18next";

export default function BuildingTypeFilter() {
    const {t} = useTranslation();
    const {setBuildingType} = useContext(SearchContext)
    const buildingTypeHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        try {
            const bt = event.target.value as BuildingType;
            setBuildingType(bt)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <Select
            height="44px"
            width="100%"
            variant="filled"
            defaultValue={BuildingType.APARTMENT}
            onChange={buildingTypeHandler}
        >
            {
                Object.values(BuildingType).map((type) => (
                    <option key={type} value={type}>
                        {t(`BuildingType.${type}`)}
                    </option>
                ))
            }
        </Select>
    )
}