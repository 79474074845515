import React, {useContext} from "react";
import {SearchContext} from "../../context/SearchProvider";
import {Box, Checkbox, Select, Text, VStack} from "@chakra-ui/react";
import {Interior} from "../../api/model";
import {useTranslation} from "react-i18next";

export default function SaleFeaturesFilter() {
    const {t} = useTranslation();
    const {setInteriorFeatures, saleFeatures, handleSaleFeaturesChange} = useContext(SearchContext);

    return (
        <>
            <VStack
                justify='space-between'
                my='24px' spacing={"16px"}
            >

                    <Box width={{base: "100%", lg: "100%"}}>
                        <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('interior')}</Text>
                        <Select
                            height="44px"
                            width="100%"
                            variant="filled"
                            placeholder={t('interior')}
                            onChange={e => setInteriorFeatures(e.target.value)}
                            colorScheme={"green"}
                        >
                            {
                                Object.values(Interior).map((type) => (
                                    <option key={type} value={type}>
                                        {t(`Interior.${type}`)}
                                    </option>
                                ))
                            }
                        </Select>
                    </Box>

                <Box width={{base: "100%", lg: "100%"}}>
                    <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('facilities')}</Text>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.concierge || false}
                        name={"concierge"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('concierge')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.gatedCommunity || false}
                        name={"gatedCommunity"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('gatedCommunity')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.openParking || false}
                        name={"openParking"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('openParking')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.closedParking || false}
                        name={"closedParking"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('closedParking')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.closedTerritory || false}
                        name={"closedTerritory"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('closedTerritory')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.gym || false}
                        name={"gym"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('gym')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.playground || false}
                        name={"playground"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('playground')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.recreationArea || false}
                        name={"recreationArea"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('recreationArea')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.pool || false}
                        name={"pool"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('pool')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.terrace || false}
                        name={"terrace"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('terrace')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.wifi || false}
                        name={"wifi"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('wifi')}
                    </Checkbox>
                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.washingMachine || false}
                        name={"washingMachine"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('washingMachine')}
                    </Checkbox>

                    <Checkbox
                        colorScheme="green"
                        width={"100%"}
                        isChecked={saleFeatures.smokingArea || false}
                        name={"smokingArea"}
                        onChange={handleSaleFeaturesChange}
                    >
                        {t('smokingArea')}
                    </Checkbox>
                </Box>

                <VStack width={"100%"}>
                    <Box width={{base: "100%", lg: "100%"}}>
                        <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('kitchen')}</Text>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.fridge || false}
                            name={"fridge"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('fridge')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.dishwasher || false}
                            name={"dishwasher"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('dishwasher')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.diningTable || false}
                            name={"diningTable"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('diningTable')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.kitchenUtensils || false}
                            name={"kitchenUtensils"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('kitchenUtensils')}
                        </Checkbox>
                    </Box>

                    <Box width={{base: "100%", lg: "100%"}}>
                        <Text fontSize={"16px"} textColor={"#2d9d92"}>{t('livingRoom')}</Text>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.appliances || false}
                            name={"appliances"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('appliances')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.tv || false}
                            name={"tv"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('tv')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.airConditioning || false}
                            name={"airConditioning"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('airConditioning')}
                        </Checkbox>
                        <Checkbox
                            colorScheme="green"
                            width={"100%"}
                            isChecked={saleFeatures.wardrobe || false}
                            name={"wardrobe"}
                            onChange={handleSaleFeaturesChange}
                        >
                            {t('wardrobe')}
                        </Checkbox>
                    </Box>
                </VStack>
            </VStack>
        </>
    )
}