import React, {createContext, useContext, useMemo, useState} from "react";
import {BuildingType, Filter, FilterProps, Interior, RentalFeatures, SaleFeatures, StatusForUsers} from "../api/model";
import {useDisclosure} from "@chakra-ui/react";
import {ThaiRaiContext} from "./HouseProvider";

export const defaultRentalFeatures = {
    id: 0,
    realEstateId: 0,
    rentalTime: null,
    withPet: false,
    withChildren: false,
    airportTransfer: false,
    beachTransfer: false,
    concierge: false,
    openParking: false,
    closedParking: false,
    seaView: false,
    lakeView: false,
    mountainView: false,
    forestView: false,
    courtyard: false,
    pool: false,
    outdoorShower: false,
    bbqArea: false,
    outdoorDining: false,
    poolTable: false,
    gymEquipment: false,
    car: false,
    motorbike: false,
    sleepingPlaces: 0,
    workspace: false,
    wifi: false,
    tv: false,
    airConditioning: false,
    fridge: false,
    dishwasher: false,
    diningTable: false,
    kitchenUtensils: false,
    bathtub: false,
    shower: false,
    washingMachine: false,
    cleaning: false,
    linenChange: false,
    videoSurveillance: false,
    closedTerritory: false,
    smokeDetector: false,
    firstAidKit: false,
    fireExtinguisher: false,
    carbonMonoxideDetector: false,
    smokingArea: false,
    interior: null
}

export const defaultSaleFeatures = {
    id: 0,
    realEstateId: 0,
    concierge: false,
    gatedCommunity: false,
    openParking: false,
    closedParking: false,
    closedTerritory: false,
    gym: false,
    playground: false,
    recreationArea: false,
    pool: false,
    terrace: false,
    fridge: false,
    dishwasher: false,
    diningTable: false,
    kitchenUtensils: false,
    appliances: false,
    tv: false,
    airConditioning: false,
    wardrobe: false,
    washingMachine: false,
    smokingArea: false,
    wifi: false,
    interior: null
}

const searchContext: FilterProps = {
    buildingType: null,
    setBuildingType() {
    },
    status: null,
    setStatus() {
    },
    tambon: null,
    setTambon() {
    },
    areaFrom: null,
    setAreaFrom() {
    },
    areaTo: null,
    setAreaTo() {
    },
    priceFrom: null,
    setPriceFrom() {
    },
    priceTo: null,
    setPriceTo() {
    },
    newBuilding: null,
    setNewBuilding() {
    },
    roomItems: [],
    setRoomItems() {
    },
    setRentTime() {
    },
    saleFeatures: defaultSaleFeatures,
    setSaleFeatures() {
    },
    rentalFeatures: defaultRentalFeatures,
    setRentalFeatures() {
    },
    handleSaleFeaturesChange(event: React.ChangeEvent<HTMLInputElement>) {
    },
    handleRentalFeaturesChange(event: React.ChangeEvent<HTMLInputElement>) {
    },
    setSleepingPlaces(value: number) {
    },
    setRentalTime(value: string) {
    },
    setInteriorFeatures(value: string) {
    },
    resetFeaturesFilter() {
    },
    isOpen: false,
    onOpen() {
    },
    onClose() {
    },
    searchHandler() {
    },
    resetFilters() {
    }
}

export const SearchContext = createContext<FilterProps>(searchContext);

export default function SearchProvider({children}: Readonly<{
    children: React.ReactNode
}>): React.ReactElement<FilterProps> {
    const [buildingType, setBuildingType] = useState(BuildingType.APARTMENT);
    const [status, setStatus] = useState(StatusForUsers.SALE);
    const [tambon, setTambon] = useState<string | null>(null);
    const [priceFrom, setPriceFrom] = useState<number | null>(null);
    const [priceTo, setPriceTo] = useState<number | null>(null);
    const [areaFrom, setAreaFrom] = useState<number | null>(null);
    const [areaTo, setAreaTo] = useState<number | null>(null);
    const [newBuilding, setNewBuilding] = useState<boolean | null>(null);
    const [roomItems, setRoomItems] = useState([false, false, false, false, false, false, false])
    const {isOpen, onOpen, onClose} = useDisclosure()
    const {setFilter} = useContext(ThaiRaiContext)

    const [saleFeatures, setSaleFeatures] = useState<SaleFeatures>(defaultSaleFeatures);

    const handleSaleFeaturesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;

        setSaleFeatures((prevState) => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const [rentalFeatures, setRentalFeatures] = useState<RentalFeatures>(defaultRentalFeatures);

    const handleRentalFeaturesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, type, checked, value} = event.target;
        setRentalFeatures((prevState) => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const setSleepingPlaces = (value: number) => {
        setRentalFeatures((prevState) => ({
            ...prevState,
            sleepingPlaces: value,
        }))
    };

    const setRentalTime = (value: string | null) => {
        setRentalFeatures((prevState) => ({
            ...prevState,
            rentalTime: value,
        }))
    };

    const setInteriorFeatures = (value: string) => {
        try {
            const interior = value as Interior;

            if (status === "RENT" && interior) {
                setRentalFeatures((prevState) => ({
                    ...prevState,
                    interior: interior,
                }))
            }

            if (status === "SALE" && interior) {
                setSaleFeatures((prevState) => ({
                    ...prevState,
                    interior: interior,
                }))
            }
        } catch (e) {
            console.log(e)
        }
    };

    const resetFilters = () => {
        setBuildingType(BuildingType.APARTMENT)
        setStatus(StatusForUsers.SALE)
        setTambon(null)
        setPriceFrom(null)
        setPriceTo(null)
        setAreaFrom(null)
        setAreaTo(null)
        setNewBuilding(null)
        setRoomItems([false, false, false, false, false, false, false])
        setSaleFeatures(defaultSaleFeatures)
        setRentalFeatures(defaultRentalFeatures)
        setRentalTime(null)
        setFilter(null)
    }

    const resetFeaturesFilter = () => {
        setSaleFeatures(defaultSaleFeatures)
        setRentalFeatures(defaultRentalFeatures)
    }

    const searchHandler = () => {
        const filter: Filter = {
            buildingType: buildingType,
            status: status,
            tambon: tambon,
            areaFrom: areaFrom,
            areaTo: areaTo,
            priceFrom: priceFrom,
            priceTo: priceTo,
            newBuilding: newBuilding,
            roomItems: roomItems,
            saleFeaturesDto: saleFeatures,
            rentalFeaturesDto: rentalFeatures
        }

        setFilter(filter)
        console.log(filter)
    }

    const obj: FilterProps = useMemo(() => ({
        buildingType: buildingType,
        setBuildingType: setBuildingType,
        status: status,
        setStatus: setStatus,
        tambon: tambon,
        setTambon: setTambon,
        areaFrom: areaFrom,
        setAreaFrom: setAreaFrom,
        areaTo: areaTo,
        setAreaTo: setAreaTo,
        priceFrom: priceFrom,
        setPriceFrom: setPriceFrom,
        priceTo: priceTo,
        setPriceTo: setPriceTo,
        newBuilding: newBuilding,
        setNewBuilding: setNewBuilding,
        roomItems: roomItems,
        setRoomItems: setRoomItems,
        setRentTime: setRentalTime,
        saleFeatures: saleFeatures,
        setSaleFeatures: setSaleFeatures,
        rentalFeatures: rentalFeatures,
        setRentalFeatures: setRentalFeatures,
        handleSaleFeaturesChange: handleSaleFeaturesChange,
        handleRentalFeaturesChange: handleRentalFeaturesChange,
        setSleepingPlaces: setSleepingPlaces,
        setRentalTime: setRentalTime,
        setInteriorFeatures: setInteriorFeatures,
        resetFeaturesFilter: resetFeaturesFilter,
        isOpen: isOpen,
        onOpen: onOpen,
        onClose: onClose,
        searchHandler: searchHandler,
        resetFilters: resetFilters
    }), [buildingType, status, tambon, areaFrom, areaTo, priceFrom, priceTo, newBuilding, roomItems, saleFeatures, rentalFeatures, setInteriorFeatures, isOpen, onOpen, onClose, resetFilters]);

    return (
        <SearchContext.Provider value={obj}>
            {children}
        </SearchContext.Provider>
    );
}