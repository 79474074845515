import {HStack} from "@chakra-ui/react";
import React, {useContext, useState} from "react";
import home from "../../assets/images/icons/home.png"
import add from "../../assets/images/icons/add(1).png"
import user from "../../assets/images/icons/user.png"
import heart from "../../assets/images/icons/heart.png"
import messages from "../../assets/images/icons/messages.png"
import {useNavigate} from "react-router-dom";
import {ThaiRaiContext} from "../../context/HouseProvider";
import "../../App.css"
import {useTranslation} from "react-i18next";

export default function NewNavMobile() {
    const {t} = useTranslation();
    const [activeButton, setActiveButton] = useState<string | null>(null);
    let navigate = useNavigate();
    const {resetFilter} = useContext(ThaiRaiContext);

    const addHouseHandleClick = () => {
        navigate("/add-house");
        setActiveButton('addHouse');
    }

    const profileHandleClick = () => {
        if (localStorage.getItem('accessToken') != null) {
            navigate("/profile");
            setActiveButton('profile');
        } else {
            navigate("/signin");
            setActiveButton('profile');
        }
    }

    const toMainPage = () => {
        resetFilter();
        navigate("/");
        setActiveButton('mainPage');
    }

    const messagesHandleClick = () => {
        navigate("/chats");
        setActiveButton('messages');
    }

    const favoritesHandleClick = () => {
        if (localStorage.getItem('accessToken') != null) {
            navigate("/favorites");
            setActiveButton('favorites');
        } else {
            navigate("/signin");
            setActiveButton('profile');
        }

    }

    return (
        /*<HStack
            height={"50px"}
            position="fixed"
            bottom={0}
            padding={0}
            width="100%"
            backgroundColor="white"
            boxShadow="0px -4px 4px rgba(0, 0, 0, 0.1)"
        >*/
        <div
            style={{
                height: '50px',
                position: 'fixed',
                bottom: 0,
                padding: 0,
                width: '100%',
                backgroundColor: 'white',
                boxShadow: '0px -4px 4px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'space-around',
                alignItems: 'center',
            }}
        >
            <button onClick={toMainPage}
                    style={{background: "transparent", border: "none", flex: 1, height: "100%", padding: 0}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 0}}>
                    <img className={"green_icon"} src={home} alt={t('main')} style={{width: "25px", height: "25px"}}/>
                    <span className="my-span">{t('main')}</span>
                </div>
            </button>
            <button onClick={favoritesHandleClick}
                    style={{background: "transparent", border: "none", flex: 1, height: "100%", padding: 0}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 0}}>
                    <img src={heart} alt={t('favorite')} style={{width: "25px", height: "25px"}}/>
                    <span className="my-span">{t('favorite')}</span>
                </div>
            </button>
            <button onClick={addHouseHandleClick}
                    style={{background: "transparent", border: "none", flex: 1, height: "100%", padding: 0}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 0}}>
                    <img src={add} alt={t('advertisements')} style={{width: "25px", height: "25px"}}/>
                    <span className="my-span">{t('advertisements')}</span>
                </div>
            </button>
            <button onClick={messagesHandleClick}
                    style={{background: "transparent", border: "none", flex: 1, height: "100%", padding: 0}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 0}}>
                    <img src={messages} alt={t('messages')} style={{width: "25px", height: "25px"}}/>
                    <span className="my-span">{t('messages')}</span>
                </div>
            </button>
            <button onClick={profileHandleClick}
                    style={{background: "transparent", border: "none", flex: 1, height: "100%", padding: 0}}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", padding: 0}}>
                    <img src={user} alt={t('profile')} style={{width: "25px", height: "25px"}}/>
                    <span className="my-span">{t('profile')}</span>
                </div>
            </button>
        </div>
    )
}
