export const countries = ["Thailand"]

export const provinces = ["Phuket"]

export const districts = ["Talang", "Kathu", "Muang"]

//export const regionsInCity = ["Тхеп Красаттри", "Си Сунтхон", "Чонг Тхал", "Па Клок", "Маи Као", "Саху", "Катху", "Патонг", "Камала", "Талат Яй", "Талат Нуеа", "Ко Као", "Растада", "Вичит", "Чалонг", "Раваи", "Карон"]

export const tambons = [
    "Thep_Krasattri",
    "Si_Soonthon",
    "Chong_Thal",
    "Pa_Klok",
    "Mai_Khao",
    "Sahu",
    "Kathu",
    "Patong",
    "Kamala",
    "Talat_Yai",
    "Talat_Nuea",
    "Koh_Khao",
    "Rastada",
    "Wichit",
    "Chalong",
    "Rawai",
    "Karon"
]
//["Thep Krasattri", "Si Soonthon", "Chong Thal", "Pa Klok", "Mai Khao", "Sahu", "Kathu", "Patong", "Kamala", "Talat Yai", "Talat Nuea" , "Koh Khao", "Rastada", "Wichit", "Chalong", "Rawai", "Karon"]

export const talangTambons = ["Thep_Krasattri", "Si_Soonthon", "Chong_Thal", "Pa_Klok", "Mai_Khao", "Sahu"]

export const kathuTambons = ["Kathu", "Patong", "Kamala"]

export const muangTambons = ["Talat_Yai", "Talat_Nuea", "Koh_Khao", "Rastada", "Wichit", "Chalong", "Rawai", "Karon"]