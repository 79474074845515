import {API_KEY, MAP_ID} from "../../index";
import {Circle} from "./circle";
import {Map, APIProvider} from "@vis.gl/react-google-maps";
import React, {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";

interface MapProps {
    center: { lat: number, lng: number };
    draggableOn?: boolean;
    setCenter?: Dispatch<SetStateAction<{ lat: number; lng: number; }>>;
}

function MyMap({center, draggableOn, setCenter}: MapProps) {
    const {i18n} = useTranslation();
    const [radius, setRadius] = React.useState(43000);
    const changeCenter = (newCenter: google.maps.LatLng | null) => {
        if (!newCenter) return;
        if (setCenter) {
            setCenter({lat: newCenter.lat(), lng: newCenter.lng()});
        }
    };

    const lng = () => {
        return i18n.language
    }

    return (
        <APIProvider apiKey={API_KEY} language={lng()}>
            <Map
                mapId={MAP_ID}
                style={{width: '100%', height: '50vh'}}
                defaultCenter={{
                    lat: center.lat,
                    lng: center.lng
                }}
                defaultZoom={14}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
            >
                <Circle
                    radius={1000}
                    center={{
                        lat: center.lat,
                        lng: center.lng
                    }}
                    onCenterChanged={changeCenter}
                    strokeColor={'#0c4cb3'}
                    strokeOpacity={1}
                    strokeWeight={3}
                    fillColor={'#3b82f6'}
                    fillOpacity={0.3}
                    draggable={draggableOn || false}
                />
            </Map>
        </APIProvider>
    );
}

export default MyMap;