import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import "./i18n/config"
import {APIProvider} from "@vis.gl/react-google-maps";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
export const API_KEY = "AIzaSyCgtxYIC4JqIRa07Rj3s1bQVEjizwPopgo"
export const MAP_ID = "85484065c7f8e4b4"
root.render(
    <BrowserRouter>
        <Suspense fallback="...is loading">
            {/*<APIProvider apiKey={API_KEY} language="ru">*/}
            <App/>
            {/*</APIProvider>*/}
        </Suspense>
    </BrowserRouter>
);
